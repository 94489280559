<template><div></div></template>
<script>
  
  import p_area_label_data from '../assets/data/p_area_labels.json';
  import p_center_label_data from '../assets/data/p_center_labels.json';
  import p_region_label_data from '../assets/data/p_region_labels.json';

  export default {
                  
    name: 'layerComponent',

    emits:[
      'layer_list',
    ],

    props: {
            'map': {
              type: Object,
              default: null 
            },
            'opacity': {
              type: Number,
              default: .8 
            },
            'popup': {
              type: Object,
              default: null 
            },
            'selected_layers': {
              type: Object,
              default: null 
            },
            'selection': {
              type: Object,
              default: null 
            },
            'style_change': {
              type: Boolean,
              default: null 
            },
    },

    data(){
      return{
        layer_list: [],

        labels: {
          p_area_labels:{
            'source':{
              'name': 'labels_p_areas',
              'type': 'geojson',
              'data': null
            },
              'layer': {
              'id': 'labels_p_areas',
              'type': 'symbol',
              'source': 'labels_p_areas',
              'style': { 'font-scale': 0.8,
                          'text-color': '#000000',
                          'text-font': [
                            'literal',
                            ['DIN Offc Pro Italic', 
                            'Arial Unicode MS Regular']
                          ]},
              'label_column': 'name'
            }
          },
          p_center_labels:{
            'source':{
              'name': 'labels_p_centers',
              'type': 'geojson',
              'data': null
            },
            'layer': {
            'id': 'labels_p_centers',
            'type': 'symbol',
            'source': 'labels_p_centers',
            'style': { 'font-scale': 0.8,
                        'text-color': '#000000',
                        'text-font': [
                          'literal',
                          ['DIN Offc Pro Italic', 
                          'Arial Unicode MS Regular']
                        ]},
            'label_column': 'name'
            }
          },
          p_region_labels:{
            'source':{
              'name': 'labels_p_regions', 
              'type': 'geojson',
              'data': null
            },
            'layer': {
            'id': 'labels_p_regions',
            'type': 'symbol',
            'source': 'labels_p_regions',
            'style': { 'font-scale': 0.8,
                        'text-color': '#000000',
                        'text-font': [
                          'literal',
                          ['DIN Offc Pro Italic', 
                          'Arial Unicode MS Regular']
                        ]},
            'label_column': 'name'
            }
          }
        },

        layers:{
          'buffered_roads':  
          {'source':
            { 'name': 'buffered_roads',
              'type': 'vector',
              'tiles': 'road_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'buffered_roads',
              'type': 'fill',
              'source': 'buffered_roads',
              'source_layer': 'tiles.road_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#8d8d8d', 'fill-opacity': .8
              }
            }
          },
          'parcel_voids':
          {'source':
            { 'name': 'parcel_voids',
              'type': 'vector',
              'tiles': 'nopar_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'parcel_voids',
              'type': 'fill',
              'source': 'parcel_voids',
              'source_layer': 'tiles.nopar_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#333333', 'fill-opacity': .8
              }
            }
          },
          'high_slopes':
          {'source':
            { 'name': 'high_slopes',
              'type': 'vector',
              'tiles': 'slopes',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'high_slopes',
              'type': 'fill',
              'source': 'high_slopes',
              'source_layer': 'tiles.slopes',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#800000', 'fill-opacity': .8
              }
            }
          },
          'urban_land':
          {'source':
            { 'name': 'urban_land',
              'type': 'vector',
              'tiles': 'urban',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'urban_land',
              'type': 'fill',
              'source': 'urban_land',
              'source_layer': 'tiles.urban',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#FF1710', 'fill-opacity': .8
              }
            }
          },
          'dep_openspace':
          {'source':
            { 'name': 'dep_openspace',
              'type': 'vector',
              'tiles': 'os_dep',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'dep_openspace',
              'type': 'fill',
              'source': 'dep_openspace',
              'source_layer': 'tiles.os_dep',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#004d00', 'fill-opacity': .8
              }
            }
          },
          'rowan_openspace':
          {'source':
            { 'name': 'rowan_openspace',
              'type': 'vector',
              'tiles': 'os_rowan',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'rowan_openspace',
              'type': 'fill',
              'source': 'rowan_openspace',
              'source_layer': 'tiles.os_rowan',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#006600', 'fill-opacity': .8
              }
            }
          },
          'fema_100':
          {'source':
            { 'name': 'fema_100',
              'type': 'vector',
              'tiles': 'fema3',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'fema_100',
              'type': 'fill',
              'source': 'fema_100',
              'source_layer': 'tiles.fema3',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#F28C28', 'fill-opacity': .8
              }
            }
          },
          'fema_tidal':
          {'source':
            { 'name': 'fema_tidal',
              'type': 'vector',
              'tiles': 'fema5',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'fema_tidal',
              'type': 'fill',
              'source': 'fema_tidal',
              'source_layer': 'tiles.fema5',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#FFAC1C', 'fill-opacity': .8
              }
            }
          },
          'njfpp':
          {'source':
            { 'name': 'njfpp',
              'type': 'vector',
              'tiles': 'njfpp',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'njfpp',
              'type': 'fill',
              'source': 'njfpp',
              'source_layer': 'tiles.njfpp',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#264d00', 'fill-opacity': .8
              }
            }
          },
          'padus':
          {'source':
            { 'name': 'padus',
              'type': 'vector',
              'tiles': 'padus',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'padus',
              'type': 'fill',
              'source': 'padus',
              'source_layer': 'tiles.padus',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#336600', 'fill-opacity': .8
              }
            }
          },
          'buffered_nhd_streams':
          {'source':
            { 'name': 'buffered_nhd_streams',
              'type': 'vector',
              'tiles': 'nhd_str_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'buffered_nhd_streams',
              'type': 'fill',
              'source': 'buffered_nhd_streams',
              'source_layer': 'tiles.nhd_str_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#003cb3', 'fill-opacity': .8
              }
            }
          },
          'buffered_c1_streams':
          {'source':
            { 'name': 'buffered_c1_streams',
              'type': 'vector',
              'tiles': 'c1_str_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'buffered_c1_streams',
              'type': 'fill',
              'source': 'buffered_c1_streams',
              'source_layer': 'tiles.c1_str_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#3068d9', 'fill-opacity': .8
              }
            }
          },
          'buffered_nhd_waterbodies':
          {'source':
            { 'name': 'buffered_nhd_waterbodies',
              'type': 'vector',
              'tiles': 'nhd_wa_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'buffered_nhd_waterbodies',
              'type': 'fill',
              'source': 'buffered_nhd_waterbodies',
              'source_layer': 'tiles.nhd_wa_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#002db3', 'fill-opacity': .8
              }
            }
          },
          'buffered_dep_water':
          {'source':
            { 'name': 'buffered_dep_water',
              'type': 'vector',
              'tiles': 'wetwa_buf',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'buffered_dep_water',
              'type': 'fill',
              'source': 'buffered_dep_water',
              'source_layer': 'tiles.muni_wetwa_buf',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#00ffc3', 'fill-opacity': .8
              }
            }
          },
          'highlands':
          {'source':
            { 'name': 'highlands',
              'type': 'vector',
              'tiles': 'highlands',
              'labels': 'p_region_labels',
              'minzoom': 6,
              'maxzoom': 13,
            },
          'layer': {
              'id': 'highlands',
              'type': 'fill',
              'source': 'highlands',
              'source_layer': 'tiles.muni_highlands',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#cccc00', 'fill-opacity': .8
              }
            }
          },
          'pinelands':
          {'source':
            { 'name': 'pinelands',
              'type': 'vector',
              'tiles': 'pinelands',
              'labels': 'p_region_labels',
              'minzoom': 6,
              'maxzoom': 13,
            },
          'layer': {
              'id': 'pinelands',
              'type': 'fill',
              'source': 'pinelands',
              'source_layer': 'tiles.muni_pinelands',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#669900', 'fill-opacity': .8
              }
            }
          },
          'meadowlands':
          {'source':
            { 'name': 'meadowlands',
              'type': 'vector',
              'tiles': 'meadowlands',
              'labels': 'p_region_labels',
              'minzoom': 6,
              'maxzoom': 13,
            },
          'layer': {
              'id': 'meadowlands',
              'type': 'fill',
              'source': 'meadowlands',
              'source_layer': 'tiles.muni_meadowlands',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#cc7a00', 'fill-opacity': .8
              }
            }
          },
          'p_areas':
          {'source':
            { 'name': 'p_areas',
              'type': 'vector',
              'tiles': 'plan_area',
              'labels': 'p_area_labels',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'p_areas',
              'type': 'fill',
              'source': 'p_areas',
              'source_layer': 'tiles.muni_planning_areas',
              'paint':  {
                  'fill-color': [
                    'match', ['get', 'section'],
                    '1', '#69F934',
                    '2', '#F84B3A',
                    '3', '#05A1A5',
                    '4', '#7B6110',
                    '5', '#6EA654',
                    '6', '#1641F9',
                    '7', '#B2A128',
                    '8', '#DD44C3', 
                    '9', '#36DB3E',
                    '10', '#07903A',
                    '11', '#4FEDD6',
                    '12', '#AB38CD', 
                    '13', '#E61CC8', 
                    '14', '#CBF248', 
                    '42', '#9A08EA',
                    '52', '#F731C4',
                    '99', '#B563AA',
                    'transparent'
                  ],
                  'fill-outline-color': '#000000',
                  'fill-opacity': .8
                }
            }
          },

          'p_centers':
          {'source':
            { 'name': 'p_centers',
              'type': 'vector',
              'tiles': 'plan_ctr',
              'labels': 'p_center_labels',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'p_centers',
              'type': 'fill',
              'source': 'p_centers',
              'source_layer': 'tiles.muni_planning_centers',
              'paint':  {'fill-outline-color': 'transparent',
                          'fill-color': '#b30000', 'fill-opacity': .8
              }
            }
          },
          'sewers':
          {'source':
            { 'name': 'sewers',
              'type': 'vector',
              'tiles': 'sewers',
              'minzoom': 6,
              'maxzoom': 14,
            },
          'layer': {
              'id': 'sewers',
              'type': 'fill',
              'source': 'sewers',
              'source_layer': 'tiles.muni_sewered',
              'paint':  {
                  "fill-color": [
                    'case',
                    ['==', ['get', 'sewered'], true],
                      '#cc6600',
                     'transparent'
                  ],
                  'fill-outline-color': 'transparent',
                  'fill-opacity': .8
              }
            }
          },
        },
        p_area_label_data,
        p_center_label_data,
        p_region_label_data,
        regions: ['highlands','pinelands','meadowlands'],
        tile_root: 'https://d3u20sxscxyinr.cloudfront.net/geolimited/muni/'
      }
    },

    watch:{

      p_area_label_values: {
        deep: true,
        handler(){
          this.labels['p_area_labels'].source.data = this.p_area_label_values;
          const label_id = this.labels['p_area_labels'].layer.id;
          if(this.map.getLayer(label_id)){
            this.map.getSource(this.labels['p_area_labels'].source.name)
                .setData(this.p_area_label_values);
          }
        }
      },

      p_center_label_values: {
        deep: true,
        handler(){
          this.labels['p_center_labels'].source.data = 
                                                  this.p_center_label_values;
          const label_id = this.labels['p_center_labels'].layer.id;
          if(this.map.getLayer(label_id)){
            this.map.getSource(this.labels['p_center_labels'].source.name)
                .setData(this.p_center_label_values);
          }
        }
      },

      p_region_label_values: {
        deep: true,
        handler(){
          this.labels['p_region_labels'].source.data = 
                                                  this.p_region_label_values;
          const label_id = this.labels['p_region_labels'].layer.id;
          if(this.map.getLayer(label_id)){
            this.map.getSource(this.labels['p_region_labels'].source.name)
                .setData(this.p_region_label_values);
          }
        }
      },

      selected_layers: {
        deep: true,
        handler(){
          this.updateDisplay();
        }
      },

      selection: {
        deep: true,
        handler(){
          if(!this.selection){
            return;
          }else{
            this.updateDisplay();
          }
        }
      }
    },

    computed: {
      p_area_label_values: function(){
        if(!this.selection) {
          return null;
        }
        if(!this.selection.id) {
          return null;
        }
        const data = this.p_area_label_data
          .filter(({ mun_code }) => mun_code == this.selection.id)
        
        return data.length>0?data[0].features:null;
      },

      p_center_label_values: function(){
        if(!this.selection) {
          return null;
        }
        if(!this.selection.id) {
          return null;
        }
        const data = this.p_center_label_data
          .filter(({ mun_code }) => mun_code == this.selection.id)
        
        return data.length>0?data[0].features:null;
      },

      p_region_label_values: function(){
        if(!this.selection) {
          return null;
        }
        if(!this.selection.id) {
          return null;
        }
        const data = this.p_region_label_data
          .filter(({ mun_code }) => mun_code == this.selection.id)
        
        return data.length>0?data[0].features:null;
      }
    },

    methods:{

      addLabels(layer_id){
        if (!('labels' in this.layers[layer_id].source)){
          return;
        }
        let label_id = '';
        if(layer_id == 'p_regions'){
          label_id = 'p_region_labels';
        }else{
         label_id = this.layers[layer_id].source.labels;
        }
        const label_data = this.labels[label_id].source.data
        if(!this.map.getLayer(this.labels[label_id].layer.id)){
          this.map.addLayer({
            'id': this.labels[label_id].layer.id,
            'type': this.labels[label_id].layer.type,
            'source': this.labels[label_id].source.name
          });
        }else{
          return;
        }
        this.map.getSource(this.labels[label_id].source.name)
                .setData(label_data);
        this.map.setLayoutProperty(
          this.labels[label_id].layer.id,
          'text-field',
          ['format', 
          ["upcase",['get', 'section']],'-',['get', 'name']
          ] 
        );
      },
      
      addLayers(){
        for (const [key, value] of Object.entries(this.selected_layers)){
          if(value){
            if(key == 'p_regions'){
              for(const region of this.regions){
                if(!this.map.getLayer(region)){
                  this.map.addLayer({
                    'id': this.layers[region].layer.id,
                    'type': this.layers[region].layer.type,
                    'source': this.layers[region].source.name, 
                    'source-layer': this.layers[region].layer.source_layer,
                    'paint': this.layers[region].layer.paint
                  });
                }
              }
              this.addLabels(key);
            }else{
              if(!this.map.getLayer(key)){
                this.map.addLayer({
                  'id': this.layers[key].layer.id,
                  'type': this.layers[key].layer.type,
                  'source': this.layers[key].source.name, 
                  'source-layer': this.layers[key].layer.source_layer,
                  'paint': this.layers[key].layer.paint
                });
              }
              this.addLabels(key);
            }
          }else{
            if(this.map.getLayer(key)){
              this.map.removeLayer(key);
              if ('labels' in this.layers[key].source){
                if(this.map.getLayer(this.labels[this.layers[key]].source.labels
                   .layer.id)){
                  this.map.removeLayer(this.labels[this.layers[key]].source
                  .labels.layer.id);
                }
              }
            }
            if(key == 'p_regions'){
              for(const region of this.regions){
                if(this.map.getLayer(region)){
                  this.map.removeLayer(region);
                }
              }
              if(this.map.getLayer('labels_p_regions')){
                this.map.removeLayer('labels_p_regions');
              }
            }
          }
        }
      },

      addSources(){

        for (const key of Object.keys(this.layers)){
          const tiles = this.tile_root 
                        + this.layers[key].source.tiles + '/'
                        + this.selection.id 
                        + '/{z}/{x}/{y}.pbf';
          if(!this.map.getSource(this.layers[key].source.name)){
            this.map.addSource(this.layers[key].source.name, {
                'type': this.layers[key].source.type,
                'minzoom': this.layers[key].source.minzoom,
                'maxzoom': this.layers[key].source.maxzoom,
            });
          }
          this.map.getSource(this.layers[key].source.name).setTiles([tiles]);
        }
        for (const key of Object.keys(this.labels)){
          if(!this.map.getSource(this.labels[key].source.name)){
            this.map.addSource(this.labels[key].source.name, {
              'type': this.labels[key].source.type,
              'data': this.labels[key].source.data
            });
          }
        }
      },

      removeLayers(){
        for (const key of Object.keys(this.layers)){
          if(this.map.getLayer(key)){
            this.map.removeLayer(key);
            this.map.removeLayer(this.labels[this.layers[key].source.labels]
            .layer.id);
          }
        }

        for(const region of this.regions){
          if(this.map.getLayer(region)){
            this.map.removeLayer(region);
          }
        }
      },

      updateDisplay(){
        if(this.selection.id){
          this.addSources();
          this.addLayers();
        }else{
          this.removeLayers();
        }
      }
    }
  }

</script>